import { Response } from '@/types';
import http from "@/store/http";


export const getAccountInfo = (params) => 
  http.request<any, Response<any>>("/account/info", 'GET', params)

export const getAccountPage = (params) => 
  http.request<any, Response<any>>("/account/manage", 'GET', params)

export const getAccountExport = (params) =>
  http.request<any, Response<any>>("/account/export", 'GET', params)

export const addAccount = (params) => 
  http.request<any, Response<any>>("/account/manage", 'POST', params)

export const disableAccount = (params) => 
  http.request<any, Response<any>>("/account/manage", 'DELETE', params)

export const batchDisableAccount = (params) => 
  http.request<any, Response<any>>("/account/batch/disable", 'POST_JSON', params)

export const batchModifyAccount = (params) => 
  http.request<any, Response<any>>("/account/batch/modify", 'POST_JSON', params)

export const batchClearAccount = (params) => 
  http.request<any, Response<any>>("/account/batch/clear", 'POST_JSON', params)

export const batchMigrateAccount = (params) => 
  http.request<any, Response<any>>("/account/batch/migrate", 'POST_JSON', params)

export const importAccount = (params) => 
  http.request<any, Response<any>>("/account/import", 'POST_JSON', params)

export const getChangeAccountPasswordRecords = (params) => 
  http.request<any, Response<any>>("/account/change/password", 'GET', params)

export const changeAccountPassword = (params) => 
  http.request<any, Response<any>>("/account/change/password", 'POST_JSON', params)

export const changeAccountAvatar = (params) => 
  http.request<any, Response<any>>("/account/change/avatar", 'POST_JSON', params)

export const resetAccountPassword = (params) => 
  http.request<any, Response<any>>("/account/reset/password", 'POST_JSON', params)

export const getRunningTaskList = () => 
  http.request<any, Response<any>>("/account/runnint/task", 'GET')

export const getModelGroupList = () => 
  http.request<any, Response<any>>("/account/model/group", 'GET')

export const saveModelGroup = (params) => 
  http.request<any, Response<any>>("/account/model/group", 'POST_JSON', params)

export const groupAllocateModel = (params) => 
  http.request<any, Response<any>>("/account/model/group/allocate", 'POST_JSON', params)
