import Mock from 'mockjs';

const presetList = [
  // {
  //   id: 26,
  //   name: 'myapp',
  //   title: '我的应用',
  //   icon: 'AppstoreAddOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/myapp',
  //   component: '@/components/layout/BlankView.vue',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: false,
  // },
  // {
  //   id: 1,
  //   name: 'workplace',
  //   title: '工作台',
  //   icon: 'DashboardOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/workplace',
  //   component: '@/pages/workplace',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: false,
  // },
  {
    id: 9,
    name: 'chat_demo',
    title: '对话',
    icon: 'MessageOutlined',
    target: '_self',
    path: '/chat/demo',
    component: '@/pages/apikeys/ChatFrame.vue',
    renderMenu: true,
    permission: null,
    cacheable: true,
  },
  {
    id: 2,
    name: 'knowledge_base',
    title: '知识库',
    icon: 'FileAddOutlined',
    badge: '',
    target: '_self',
    path: '/knowledge_base',
    component: '@/pages/knowledge_base',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
  // {
  //   id: 3,
  //   name: 'personal',
  //   title: '个人中心',
  //   path: '/personal',
  //   icon: 'ProfileOutlined',
  //   permission: null,
  //   component: '@/pages/personal',
  //   renderMenu: true,
  //   parent: null,
  // },
  // {
  //   id: 6,
  //   name: 'system',
  //   title: '系统配置',
  //   icon: 'SettingOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/system',
  //   component: '@/components/layout/BlankView.vue',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 8,
  //   name: 'menu',
  //   title: '菜单管理',
  //   badge: '12',
  //   target: '_self',
  //   path: '/system/menu',
  //   component: '@/pages/system',
  //   renderMenu: true,
  //   parent: 'system',
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 7,
  //   name: 'user',
  //   title: '用户管理',
  //   target: '_self',
  //   path: '/system/user',
  //   component: '@/pages/user',
  //   renderMenu: true,
  //   parent: 'system',
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 9,
  //   name: 'chat_demo',
  //   title: '对话',
  //   icon: 'MessageOutlined',
  //   // badge: 'hot',
  //   target: '_self',
  //   path: '/chat/demo',
  //   component: 'iframe',
  //   renderMenu: true,
  //   permission: null,
  //   cacheable: true,
  //   link: import.meta.env.VITE_CHATBOT,
  // },  
  // {
  //   id: 10,
  //   name: 'github',
  //   title: 'Github',
  //   icon: 'GithubOutlined',
  //   badge: 'link',
  //   target: '_blank',
  //   path: '/github',
  //   component: 'link',
  //   renderMenu: true,
  //   parent: null,
  //   cacheable: true,
  //   link: 'https://github.com/stepui/stepin-template',
  // },
  {
    id: 26,
    name: 'appRelease',
    icon: 'ShareAltOutlined',
    title: '应用服务',
    target: '_self',
    path: '/myapp/release',
    component: '@/pages/apikeys/AppRelease.vue',
    renderMenu: true,
    // parent: 'myapp',
    permission: null,
    cacheable: false,
  },
  // {
  //   id: 27,
  //   name: 'data',
  //   title: '数据',
  //   icon: 'LineChartOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/myapp/data',
  //   component: '@/components/layout/BlankView.vue',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  {
    id: 271,
    name: 'apikeysUsage',
    icon: 'LineChartOutlined',
    title: '用量统计',
    target: '_self',
    path: '/myapp/usage',
    component: '@/pages/apikeys/ApiKeysUsage.vue',
    renderMenu: true,
    // parent: 'data',
    parent: null,
    permission: null,
    cacheable: false,
  },
  {
    id: 272,
    name: 'apikeysChatRecord',
    icon: 'TableOutlined',
    title: '对话记录',
    target: '_self',
    path: '/myapp/chat/record',
    component: '@/pages/apikeys/ChatRecord.vue',
    renderMenu: true,
    // parent: 'data',
    parent: null,
    permission: null,
    cacheable: false,
  },
  {
    id: 28,
    name: 'apikeys',
    title: 'API keys',
    icon: 'AppstoreAddOutlined',
    badge: '',
    target: '_self',
    path: '/myapp/apikeys',
    component: '@/pages/apikeys',
    renderMenu: true,
    // parent: 'myapp',
    permission: null,
    cacheable: false,
  },
];

function getMenuList() {
  // const menuStr = localStorage.getItem('stepin-menu');
  // let menuList = [];
  // if (!menuStr) {
  //   menuList = presetList;
  //   localStorage.setItem('stepin-menu', JSON.stringify(menuList));
  // } else {
  //   menuList = JSON.parse(menuStr);
  // }
  return presetList;
}

function getAdminMenuList(isAdmin, isWeworkBotEnv) {
  if (isWeworkBotEnv){
    const d = {
      id: 29,
      name: 'assistant',
      icon: 'RobotOutlined',
      title: '群助手',
      target: '_self',
      path: '/assistant',
      component: '@/pages/assistant/AssistantManage.vue',
      renderMenu: true,
      // parent: 'myapp',
      permission: null,
      cacheable: false,
    }
    presetList.splice(3, 0, d);
  }
  if (isAdmin){
    const d = {
      id: 30,
      name: 'accountManage',
      icon: 'UserOutlined',
      title: '账号管理',
      target: '_self',
      path: '/account/manage',
      component: '@/pages/account/AccountManage.vue',
      renderMenu: true,
      permission: null,
      cacheable: false,
    }
    presetList.push(d)
  }
  return presetList;
}

// function getLastUseApiKey(){
//   return getApiKeyList().then((response) => {
//     console.log(response);
//     const { data } = response;
//     if (data.length > 0){
//       const { api_key } = data[0];
//       return api_key;
//     }
//     return "";
//   }).catch((e) => {
//     console.error(e);
//   })
// }

// const getBotLink = async() => {
//   let last_use_api_key = await getLastUseApiKey();
//   console.log(last_use_api_key);
//   return "?api_key=" + last_use_api_key || "";
// }

function saveMenu(menu) {
  const menuList = getMenuList();
  if (!menu.id) {
    menu.id = menuList.map((item) => item.id).reduce((p, c) => Math.max(p, parseInt(c)), 0) + 1;
  }
  const index = menuList.findIndex((item) => item.id === menu.id);
  if (index === -1) {
    menuList.push(menu);
  } else {
    menuList.splice(index, 1, menu);
  }
  localStorage.setItem('stepin-menu', JSON.stringify(menuList));
}

Mock.mock('api/menu', 'get', ({}) => {
  let menuList = getMenuList();
  const menuMap = menuList.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});
  menuList.forEach((menu) => {
    menu.renderMenu = !!menu.renderMenu;
    if (menu.parent) {
      const parent = menuMap[menu.parent];
      parent.children = parent.children ?? [];
      parent.children.push(menu);
    }
  });
  return {
    message: 'success',
    code: 0,
    data: menuList.filter((menu) => !menu.parent),
  };
});

Mock.mock('api/admin/menu', 'post', ({body}) => {
  const menu = JSON.parse(body);
  let menuList = getAdminMenuList(menu.isAdmin, menu.isWeworkBotEnv);
  const menuMap = menuList.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});
  menuList.forEach((menu) => {
    menu.renderMenu = !!menu.renderMenu;
    if (menu.parent) {
      const parent = menuMap[menu.parent];
      parent.children = parent.children ?? [];
      parent.children.push(menu);
    }
  });
  return {
    message: 'success',
    code: 0,
    data: menuList.filter((menu) => !menu.parent),
  };
});

Mock.mock('api/menu', 'put', ({ body }) => {
  const menu = JSON.parse(body);
  saveMenu(menu);
  return {
    code: 0,
    message: 'success',
  };
});

Mock.mock('api/menu', 'post', ({ body }) => {
  const menu = JSON.parse(body);
  saveMenu(menu);
  return {
    code: 0,
    message: 'success',
  };
});

Mock.mock('api/menu', 'delete', ({ body }) => {
  const id = body.get('id')[0];
  let menuList = getMenuList();
  const index = menuList.findIndex((menu) => menu.id === id);
  const [removed] = menuList.splice(index, 1);
  localStorage.setItem('stepin-menu', JSON.stringify(menuList));
  return {
    code: 0,
    message: 'success',
    data: removed,
  };
});
